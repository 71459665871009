<template>
  <div class="info-block mb-2 text-sm elevation-2">
    <div :class="labelClass" class="label bg-gradient-primary-2 elevation-2 rounded">
      {{ label }}
    </div>
    <div v-if="!useSlot" class="value">{{ value }}</div>
    <div v-else class="value">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: 'Label',
    },
    value: {
      type: String,
      default: '—',
    },
    labelClass: {
      type: String,
      default: '',
    },
    useSlot: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.info-block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .label {
    color: white;
    font-weight: 600;
    width: 30%;
    white-space: nowrap;
    padding: 0.5em 1em;
  }
  .value {
    padding: 0.5em 1em;
  }
}
</style>
